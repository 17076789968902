@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  /* outline: 1px solid limegreen !important; */
}
:root {
  --bg-primary-color: #f4f9fd;
  --primary-color: #3f8cff;
  --thin-color: "#7D8592";
}

body {
  background-color: var(--bg-primary-color);
}

::-webkit-scrollbar {
  width: 4px;
  height: 6px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: #6a9ce6;
}

.no-scrollbar::-webkit-scrollbar {
  display: none !important;
}

.clamp {
  font-size: clamp(1.5rem, 5vw, 4rem);
}

.clamp1 {
  font-size: clamp(1.5rem, 5vw, 2.8rem);
}
.clamp2 {
  font-size: clamp(1.1rem, 5vw, 2rem);
}

.clamp3 {
  font-size: clamp(1.1rem, 4vw, 1.3rem);
}
.clamp4 {
  font-size: clamp(10px, 3vw, 1rem);
}
.clamp5 {
  font-size: clamp(8px, 2vw, 0.8rem);
}
.clamp5 {
  font-size: clamp(8px, 2vw, 0.7rem);
}

.clamp2Half {
  font-size: clamp(1.1rem, 5vw, 1.5rem);
}

.clamp3Half {
  font-size: clamp(0.9rem, 3.5vw, 1.1rem);
}
.clamp4Half {
  font-size: clamp(0.7rem, 3vw, 0.9rem);
}

.blur-effect {
  filter: blur(10px);
}

.light {
  --primary: 11, 92, 181, 1;
  --primarychart:#0B5CB5;

  --background: 244, 249, 253, 1;
  --background-secondary: 232, 242, 248 ,1;

  --hr-color: 228, 230, 232, 1;

  --active-card: 232, 242, 248;
  --hover-card: 244, 250, 253;

  --thin-color: 125, 133, 146, 1;
  --text-primary: 10, 22, 41, 1;
  --border: 212, 212, 212;
  --card: 255, 255, 255, 1;

  --copy-primary: 23, 23, 23;
  --copy-secondary: 115, 115, 115;

  --cta: 139, 92, 246;
  --cta-active: 124, 58, 237;
  --cta-text: 255, 255, 255;

  --button-color: 59, 130, 246;

  background: rgba(var(--background));
  color: rgba(var(--text-primary));
}

.dark {
  --primary: 255, 255, 255, 1;
  --primarychart:#fff;

  --background: 15, 18, 45;
  --background-secondary: 20, 24, 58;

  --hr-color: 56, 59, 83;

  --text-primary: 255, 255, 255, 1;
  --thin-color: 125, 133, 146, 1;

  --active-card: 30, 37, 71;
  --hover-card: 25, 30, 70;

  --border: 33, 37, 75;
  --card: 23, 29, 59, 1;

  --copy-primary: 250, 250, 250;
  --copy-secondary: 115, 115, 115;

  --cta: 99, 102, 241;
  --cta-active: 79, 70, 229;
  --cta-text: 255, 255, 255;

  --button-color: 30, 37, 71;
  color: rgba(var(--text-primary));

  background-color: rgba(var(--background));
  /* color:rgb() */
}
:root {
  --grape: 114, 35, 204;
}

.dfaf {
  color: rgb(56, 59, 83);
}

.scrollScrolbar::-webkit-scrollbar {
  height: 4px;
}
.scrollScrolbar::-webkit-scrollbar-track {
  background-color: transparent;
}
.scrollScrolbar::-webkit-scrollbar-thumb {
  background-color: #6a9ce6;
}

.chat-back {
  position: relative;
  opacity: 0.9;
}

.chat-back::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('./images/chat-back1.svg');
  opacity: 0.3;
  z-index: -1;
}
