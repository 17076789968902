.notf-cound:before {
  content: attr(data-count);
  position: absolute;
  top: -0.7em;
  right: -0.7em;
  height: 1.4em;
  width: 1.4em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: red;
  color: white;
}
