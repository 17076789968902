.saidbar-scrolbar::-webkit-scrollbar {
  width: 4px;
}
.saidbar-scrolbar::-webkit-scrollbar-track {
  background-color: transparent;
  margin: 20px;

}
.saidbar-scrolbar::-webkit-scrollbar-thumb {
  background-color: #6a9ce6;
}
